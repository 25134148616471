import {
  FormField,
  Dropdown,
  GridColumn,
  Input,
  Icon,
  Button,
  Form,
} from "semantic-ui-react"
import styled from "@emotion/styled"
import theme from "./theme"
import React from "react"
import mq from "./mq"
import { Field } from "formik"
import { Link } from "gatsby"
import { Paragraph1, Paragraph2 } from "./Global.styles"
import { isMobile } from "@utils/Helpers"

export const KpFormField = styled(({ borderRadius, ...others }) => <FormField {...others} />)`
  width: 100%;

  & > label {
    display: inline-block;
    text-align: left;
    font-size: 13px !important;
    line-height: 19px;
    float: left;
    &.sub-label {
      float: right;
      color: #707070 !important;
    }
  }

  & > a {
    cursor: pointer;
    text-decoration: underline;
    text-align: right;
    float: right;
    color: #707070;
    font-weight: bold;
    font-size: 13px !important;
    line-height: 19px;
  }

  &.error {
    & > label {
      color: ${isMobile() ? theme.brand.colors.lightGrey : theme.brand.colors.black} !important;
    }
  }

  input {
    height: 50px;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 14px 15px 12px 15px !important;
    width: 100%;
    background-color: ${props => props.backgroundColor} !important;
    border-radius: ${props => props.borderRadius}px !important;    
  }

  & > textarea {
    font-family: itc-avant-garde-gothic-pro, sans-serif;
    height: 50px;
    font-size: 16px !important;
    line-height: 16px !important;
    width: 100%;
  }

  & > select {
    font-family: itc-avant-garde-gothic-pro, sans-serif;
    width: 100%;
    height: 50px;
    font-size: 16px;
    background-color: ${props => props.backgroundColor} !important;
    border: 2px solid ${theme.brand.colors.lightGrey} !important;
    color: ${theme.brand.colors.darkGrey} !important;
    border-radius: 4px;
    padding: 15px !important;

    &:hover {
      color: ${theme.brand.colors.black} !important;
    }

    &:focus {
      outline: none !important;
    }

    &.active {
      color: ${theme.brand.colors.black} !important;
      border: 2px solid ${theme.brand.colors.darkGrey} !important;

      > .text {
        color: ${theme.brand.colors.black} !important;
      }
    }

    &.disabled {
      color: ${theme.brand.colors.darkGrey} !important;
      border: 2px solid ${theme.brand.colors.lightGrey} !important;

      > .text {
        opacity: 0.5;
      }

      > i {
        color: ${theme.brand.colors.darkGrey};
        opacity: 0.5;
      }
    }

    > i {
      float: right;
    }

    &.ui.dropdown {
      > .menu {
        border: none !important;
        border-top: 2px solid ${theme.brand.colors.darkGrey} !important;
        box-shadow: 0px 6px 16px #0000001f;
        border-radius: 0px 0px 4px 4px;

        > .item {
          height: 50px !important;
          padding: 18px !important;
          font-size: 16px;
          font-weight: normal;
          color: ${theme.brand.colors.darkGrey};

          &:hover {
            background-color: ${theme.brand.colors.white};
            color: ${theme.brand.colors.black};
          }

          &.selected {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  ${isMobile() && `p {
    color: ${theme.mobileTheme.color} !important;}`
  }

  ${isMobile() && `input {
    color: ${theme.mobileTheme.color} !important;
    background-color: ${theme.mobileTheme.bgColor} !important;
    border-radius: unset !important;
    border: 0px !important;
    border-bottom: 2px solid ${theme.mobileTheme.color} !important;

    ::-webkit-input-placeholder { /* WebKit browsers */
      text-transform: none;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        text-transform: none;
        color: ${theme.brand.colors.lightGrey}  !important;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        text-transform: none;
        color: ${theme.brand.colors.lightGrey}  !important;
    }
    :-ms-input-placeholder { /* Internet Explorer 10+ */
        text-transform: none;
        color: ${theme.brand.colors.lightGrey}  !important;
    }
    ::placeholder { /* Recent browsers */
        text-transform: none;
        color: ${theme.brand.colors.lightGrey}  !important;
    }
    }
    `
  }

  ${isMobile() && `label {
    color: ${theme.mobileTheme.secondaryColor} !important;}`
  }

  ${isMobile() && `span {
    color: ${theme.mobileTheme.secondaryColor};}`
  }

  ${isMobile() && `.text {
    color: #707070 !important;}`
  }

  ${props => props.placeholderColor && `input {
    color: black !important;
    background-color: ${props.backgroundColor} !important;
    border: 0px !important;
    border-bottom: 0px solid ${props.placeholderColor} !important;

      ::-webkit-input-placeholder { /* WebKit browsers */
        text-transform: none;
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          text-transform: none;
          color: ${props.placeholderColor}  !important;
          font-weight:100;
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
          text-transform: none;
          color: ${props.placeholderColor}  !important;
          font-weight:100;
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
          text-transform: none;
          color: ${props.placeholderColor}  !important;
          font-weight:100;
      }
      ::placeholder { /* Recent browsers */
          text-transform: none;
          color: ${props.placeholderColor}  !important;
          font-weight:100;
      }
    }

    `
  }

  .ui.checkbox input[type=checkbox]~label::before,
  .ui.checkbox input[type=checkbox]:focus~label::before {
    background: ${props => props.backgroundColor} !important;
  }

  .ui.checkbox label{
    color: ${props=>props.labelColor} !important;
  }

  .ui.checkbox input:checked ~ label:after {
    opacity: 1;
  }
  ${props => props.placeholderColor && `input {
    border-radius: 5px !important;
  }`}
`

export const KpFormDatePickerIcon = styled(({ ...others }) => (
  <Icon {...others} />
))`
  width: 55px !important;
  border-color: rgba(34, 36, 38, 0.15) !important;
  color: ${theme.brand.colors.darkGrey} !important;
  margin-right: 0 !important;
`

export const KpFormDayPickerInputIconContiner = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding-top: 12px;
  background-color: rgba(34, 36, 38, 0.15);
`

export const KpFormDayPickerInput = styled(({ ...others }) => (
  <div {...others} />
))`
  width: 250px;
  cursor: pointer;
  line-height: 1;
  padding-top: 18px;
  padding-left: 10px;
  position: relative;
  height: 50px;
  font-size: 16px !important;
  line-height: 16px !important;
  font-size: 1em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;

  input {
    cursor: pointer !important;
  }

  .react-date-picker__wrapper {
    border: none;

    .react-date-picker__inputGroup {
      padding-top: 6px;

      .react-date-picker__inputGroup__input {
        height: auto !important;
        border: none !important;
      }

      .react-date-picker__inputGroup__leadingZero {
        margin-top: 1px;
        display: inline-block;
      }
    }
  }
`

export const KpFormDatePickerInput = styled(({ ...others }) => (
  <div {...others} />
))`
  float: left;
  width: 100%;
  line-height: 1;
  height: 50px;
  font-size: 16px !important;
  line-height: 16px !important;
  font-size: 1em;
  background: #ffffff;
  border-radius: 0.28571429rem;

  input {
    cursor: pointer !important;
  }

  .react-date-picker__wrapper {
    border: none;

    .react-date-picker__inputGroup {
      padding-top: 6px;

      .react-date-picker__inputGroup__input {
        height: auto !important;
        border: none !important;
      }

      .react-date-picker__inputGroup__leadingZero {
        margin-top: 1px;
        display: inline-block;
      }
    }
  }
`

export const KpFormDropdown = styled(({ backgroundColor, ...others }) => (
  <Dropdown {...others} />
))`
  width: 100%;
  height: 50px;
  font-size: 16px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  padding: 17px 15px 13px 15px !important;
  clear: both;
  &:hover {
    color: ${theme.brand.colors.black} !important;
  }

  &.active {
    color: ${isMobile() ? theme.brand.colors.white : theme.brand.colors.black} !important;
    border: 2px solid ${theme.brand.colors.darkGrey} !important;

    > .text {
      color: ${props => props.backgroundColor === theme.brand.colors.white || props.backgroundColor === undefined ? theme.brand.colors.black : theme.brand.colors.white} !important;
    }
  }

  &.disabled {
    color: ${theme.brand.colors.darkGrey} !important;
    border: 2px solid ${theme.brand.colors.lightGrey} !important;

    > .text {
      opacity: 0.5;
    }

    > i {
      color: ${theme.brand.colors.darkGrey};
      opacity: 0.5;
    }
  }

  > i {
    float: right;
  }

  &.ui.dropdown {
    > .text{
      color: ${props => props.backgroundColor === theme.brand.colors.white || props.backgroundColor === undefined ? theme.brand.colors.black : theme.brand.colors.white} !important;
    }

    > .menu {
      border: none !important;
      max-height: 235px !important;
      border-top: 2px solid ${theme.brand.colors.darkGrey} !important;
      box-shadow: 0px 6px 16px #0000001f;
      border-radius: 0px 0px 4px 4px;
      margin-left:-2px;
      width: calc(100% + 4px);

      > .item {
        height: 50px !important;
        padding: 18px !important;
        font-size: 16px;
        font-weight: normal;
        color: ${theme.brand.colors.darkGrey};

        &:hover {
          background-color: ${theme.brand.colors.white};
          color: ${theme.brand.colors.black};
        }

        &.selected {
          background-color: transparent !important;
        }
      }
    }


  }
`

export const KpFormInputErrorMessage = styled.span`
  &&& {
    color: ${theme.brand.colors.error} !important;
    float: right !important;
    font-weight: bold;
    font-size: 13px;
  }
`
export const KpFormColumn = styled(GridColumn)`
  ${mq({
    paddingTop: ["15px !important", "5px !important", "5px !important"],
    paddingBottom: ["15px !important", "5px !important", "5px !important"],
  })}
`
export const FormikFieldEmailValidationInput = styled(
  ({ inputValid, ...others }) => <Field {...others} />
)`
  border-left: ${props =>
    props.inputValid === undefined
      ? ""
      : props.inputValid
      ? "4px solid green !important"
      : "4px solid red !important"};
  border-top: ${props =>
    props.inputValid === undefined
      ? ""
      : props.inputValid
      ? "1px solid green !important"
      : "1px solid red !important"};
  border-right: ${props =>
    props.inputValid === undefined
      ? ""
      : props.inputValid
      ? "1px solid green !important"
      : "1px solid red !important"};
  border-bottom: ${props =>
    props.inputValid === undefined
      ? ""
      : props.inputValid
      ? "1px solid green !important"
      : "1px solid red !important"};
`

export const AuthFormLabel = styled.label`
  color: ${theme.brand.colors.darkGrey} !important;
  font-weight: bold;
  display: inline-block !important;
  padding-top: 8px;
`

export const FileUploadInput = styled.input`
  position: absolute;
  margin: 0;
  padding: 0 !important;
  width: 200px !important;
  height: 200px !important;
  outline: none;
  opacity: 0;
  cursor: pointer;
`
export const DragTextInput = styled.div`
  text-align: center;
`
export const DragTextInputText = styled.div`
  text-transform: uppercase;
  padding: 60px 20px;
`
export const FileUploadWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const FileUploadItemWrapper = styled.div`
  width: 192px;
  height: 192px;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid #cccccc;
  background-color: #e8e8e8;
  border-radius: 8px;
`
export const ImageContainer = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`
export const ImageUploadWrapper = styled.div`
  border: 2px dashed #1fb264;
  width: 192px;
  height: 192px;
  margin-right: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
`
export const ImagePreviewRemove = styled.div`
  position: absolute;
  width: 24px;
  height: 22px;
  font-size: 17px;
  line-height: 20px;
  background: red;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
  margin-top: 8px;
  margin-left: 8px;
`

export const AuthTitle = styled.div`
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  padding: 0px 20px 20px 20px;
`

export const DontShowThisAgain = styled.div`
  font-size: 12px;
  color: ${theme.brand.colors.darkGrey} !important;
  text-align: center;
  padding: 20px 20px 3px 20px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const PasswordResetLink = styled(Link)`
  font-size: 12px;
  color: ${theme.brand.colors.darkGrey} !important;
  &:hover {
    text-decoration: underline;
  }
`

export const AuthInputMessage = styled.div`
  text-align: right;
  float: right;
  padding-top: 15px;
`

export const AuthSignupMessage = styled.span`
  color: ${theme.brand.colors.darkGrey};
  font-size: 12px;
  text-align: right;
`

export const InfoAuthSignupMessage = styled.span`
  color: ${theme.brand.colors.red};
  font-size: 12px;
  text-align: right;
`

export const ErrorMessageContainer = styled.p`
  text-align: center;
  margin-top: 10px;
  color: rgba(200, 0, 0, 0.8);
`
export const AuthSignupLink = styled(Link)`
  color: ${theme.brand.colors.darkGrey} !important;
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline !important;
  padding-left: 5px;
`

export const AuthSendCode = styled.span`
  color: ${theme.brand.colors.darkGrey} !important;
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline !important;
  padding-left: 5px;
  cursor: pointer;
`
export const KpFormErrorMessageLeft = styled.span`
  &&& {
    color: ${theme.brand.colors.error} !important;
    font-weight: bold;
    font-size: 13px;
    line-height: 19px;
  }
`

export const KpFormFieldAsDT = styled(({ ...others }) => <FormField {...others} />)`
  width: 100%;

  & > label {
    display: inline-block;
    text-align: left;
    font-size: 13px !important;
    line-height: 19px;
    float: left;
    &.sub-label {
      float: right;
      color: #707070 !important;
    }
  }

  & > a {
    cursor: pointer;
    text-decoration: underline;
    text-align: right;
    float: right;
    color: #707070;
    font-weight: bold;
    font-size: 13px !important;
    line-height: 19px;
  }

  &.error {
    & > label {
      color: ${isMobile() ? theme.brand.colors.lightGrey : theme.brand.colors.black} !important;
    }
  }

  input {
    height: 50px;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 14px 15px 12px 15px !important;
    width: 100%;
    background-color: ${props => props.backgroundColor} !important;
  }

  & > textarea {
    font-family: itc-avant-garde-gothic-pro, sans-serif;
    height: 50px;
    font-size: 16px !important;
    line-height: 16px !important;
    width: 100%;
  }

  & > select {
    font-family: itc-avant-garde-gothic-pro, sans-serif;
    width: 100%;
    height: 50px;
    font-size: 16px;
    background-color: ${props => props.backgroundColor} !important;
    border: 2px solid ${theme.brand.colors.lightGrey} !important;
    color: ${theme.brand.colors.darkGrey} !important;
    border-radius: 4px;
    padding: 15px !important;

    &:hover {
      color: ${theme.brand.colors.black} !important;
    }

    &:focus {
      outline: none !important;
    }

    &.active {
      color: ${theme.brand.colors.black} !important;
      border: 2px solid ${theme.brand.colors.darkGrey} !important;

      > .text {
        color: ${theme.brand.colors.black} !important;
      }
    }

    &.disabled {
      color: ${theme.brand.colors.darkGrey} !important;
      border: 2px solid ${theme.brand.colors.lightGrey} !important;

      > .text {
        opacity: 0.5;
      }

      > i {
        color: ${theme.brand.colors.darkGrey};
        opacity: 0.5;
      }
    }

    > i {
      float: right;
    }

    &.ui.dropdown {
      > .menu {
        border: none !important;
        border-top: 2px solid ${theme.brand.colors.darkGrey} !important;
        box-shadow: 0px 6px 16px #0000001f;
        border-radius: 0px 0px 4px 4px;

        > .item {
          height: 50px !important;
          padding: 18px !important;
          font-size: 16px;
          font-weight: normal;
          color: ${theme.brand.colors.darkGrey};

          &:hover {
            background-color: ${theme.brand.colors.white};
            color: ${theme.brand.colors.black};
          }

          &.selected {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  ${isMobile() && `p {
    color: ${theme.mobileTheme.color} !important;}`
  }

  ${isMobile() && `label {
    color: ${theme.mobileTheme.secondaryColor} !important;}`
  }

  ${isMobile() && `span {
    color: ${theme.mobileTheme.secondaryColor} !important;}`
  }

  ${isMobile() && `.text {
    color: #707070 !important;}`
  }

  ${props => props.placeholderColor && `input {
    color: black !important;
    background-color: ${props.backgroundColor} !important;
    border-radius: unset !important;
    border: 0px !important;
    border-bottom: 0px solid ${props.placeholderColor} !important;

      ::-webkit-input-placeholder { /* WebKit browsers */
        text-transform: none;
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          text-transform: none;
          color: ${props.placeholderColor}  !important;
          font-weight:100;
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
          text-transform: none;
          color: ${props.placeholderColor}  !important;
          font-weight:100;
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
          text-transform: none;
          color: ${props.placeholderColor}  !important;
          font-weight:100;
      }
      ::placeholder { /* Recent browsers */
          text-transform: none;
          color: ${props.placeholderColor}  !important;
          font-weight:100;
      }
    }

    `
  }

  .ui.checkbox input[type=checkbox]~label::before,
  .ui.checkbox input[type=checkbox]:focus~label::before {
    background: ${props => props.backgroundColor} !important;
  }

  .ui.checkbox label{
    color: ${props=>props.labelColor} !important;
  }

  .ui.checkbox input:checked ~ label:after {
    opacity: 1;
  }
  ${props => props.placeholderColor && `input {
    border-radius: 5px !important;
  }`}
`